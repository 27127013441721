<template>
    <div>
        
            <div class="alert alert-info mb-3 h5">  
             <template v-if="user.surname && user.firstname">
                {{user.surname}} {{user.firstname}} {{user.secondname}}
              </template>
              <template v-else>
                <template v-if="user.fillname">{{user.surname}}</template>
                <template v-else>{{user.username}}</template>
              </template>
            </div>

            <b-aspect :aspect="1.85" v-if="clubcards != null && clubcards.length > 0" class="mb-3">  
              <div class="active dark club-card">
                <div class="shadow"></div>
                <div class="right">
                  <div class="card-info">
                    <div class="card-info-1 mb-2">
                      <div class="card-name">CLUB CARD</div>
                      <div class="card-num">№ {{(clubcards[0]).cardnum}}</div>
                      <template v-if="(clubcards[0]).balance > 0">
                        <div class="card-credit" v-if="(clubcards[0]).cardlimit && (clubcards[0]).cardlimit > 0">
                          credit {{(clubcards[0]).cardlimit  | toCurrency}}
                        </div>
                      </template>
                      <template v-else>
                        <div class="card-credit text-victor" v-if="(clubcards[0]).cardlimit && (clubcards[0]).cardlimit > 0">
                          <b-icon icon="piggy-bank-fill" flip-h class="mr-1"></b-icon> {{(clubcards[0]).balance  | toCurrency}}
                        </div>  
                      </template>
                    </div>
                    <div class="card-info-2" v-if="(clubcards[0]).tarif">
                      <div class="card-tarif">{{(clubcards[0]).tarifMnemo}}</div>
                    </div>
                    <div class="card-info-3" v-if="(clubcards[0]).balance && (clubcards[0]).balance > 0">
                      <b-icon icon="piggy-bank-fill" flip-h class="mr-2"></b-icon>
                      <div class="card-ballance">{{(clubcards[0]).balance  | toCurrency}}</div>
                    </div>
                    <div class="card-info-3" v-else>
                      credit {{(clubcards[0]).cardlimit+(clubcards[0]).balance  | toCurrency}}
                    </div>
                  </div>
                  <div class="card-refill" v-if="enable_refill">  
                    <b-button class="button-card-refill bg-transparent" role="button" v-b-modal.refill-modal>
                      <b-icon icon="plus-circle" font-scale="1.2" class="mr-2"></b-icon> Поповнити
                    </b-button>
                  </div>  
                  <div class="card-holdername">
                    {{user.mnemo | capitalize }}
                  </div>  
                </div>
              </div>
            </b-aspect>


              <!--b-card header-tag="header" footer-tag="footer"  v-if="clubcards != null && clubcards.length > 0" class="mb-3">
                <template #header>
                  <h6 class="mb-0">Клубна картка №{{(clubcards[0]).cardnum}}</h6>
                </template>
                <b-card-text>
                  <template>
                    <p class="h6"></p>
                    <p class="h6" v-if="(clubcards[0]).validTo">Дійсна до {{new Date((clubcards[0]).validTo) | dateFormat('DD.MM.YYYY')}}</p>
                    <p class="h6 "> 
                        Тариф: <template v-if="(clubcards[0]).tarif">{{(clubcards[0]).tarifMnemo}}</template>
                              <template v-else>---</template>
                    </p>
                    <p class="h6 "> 
                      Баланс: <template v-if="(clubcards[0]).balance">{{(clubcards[0]).balance  | toCurrency}}</template>
                              <template v-else>0</template>
                        
                    </p>
                    <p class="h6" v-if="(clubcards[0]).cardlimit && (clubcards[0]).cardlimit > 0">
                      Кредитний ліміт: {{(clubcards[0]).cardlimit  | toCurrency}}
                    </p> 
                    </template>  
                  </b-card-text>  
                  <template #footer>
                    <b-button variant="bruvis" class="ml-0" v-b-modal.refill-modal>Поповнити баланс</b-button>
                  </template>
                </b-card-->




        <b-card header-tag="header" footer-tag="footer">
          <template #header>
            <h6 class="mb-0">Зміна пароля</h6>
          </template>
          <b-card-text>
              <ChangePass/>
          </b-card-text>
        </b-card>
  

        <!-------------------------------------------->

        <b-modal 
          id="refill-modal" 
          hide-header-close- 
          centered 
          scrollable 
          size="md" 
          :no-close-on-backdrop="true"
        >
          <template #modal-title>
            <b-icon icon="credit-card" class="rounded bg-secondary p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Поповнення балансу</span>
          </template>
          <RefillCard @onRefill = "init_afterrefill()" @needlogin = "needlogin()"/>
          <template #modal-footer>
            <b-button variant="bruvis" @click="$bvModal.hide('refill-modal')">
              Закрити
            </b-button>
          </template>
        </b-modal>  

        <!-------------------------------------------->

        <!--div v-if="clubcards != null && clubcards.length > 0">
            <b-card no-body>
                <pre>    
                    {{clubcards}}
                </pre>
            </b-card>  
        </div-->




  


    </div>
</template>

<script>
import ChangePass from './changepass.vue'
import RefillCard from './refill.vue'
export default {
  name: 'UserProfile',
  components: {
    ChangePass,
    RefillCard
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    clubcards:{
      type: Object,
      required: true
    },
    enable_refill:{
      type: Object,
      required: true
    }
  },
  data: () => ({
    oldpass: null,
    newpass: null,
    newpass2: null
  }),  
  methods: {
    init_afterrefill(){
      this.$emit('onRefill') 
    },
    needlogin(){
      this.$emit('needlogin')
    }
  }
}
</script>

<style scoped>
.club-card.dark {
  background: linear-gradient(to bottom,#003462,#004D90,#0077E2);
  box-shadow: 0px 0px 10px 2px rgb(0 0 0 / 35%);
}

.club-card.active {
  box-shadow: 0px 0px 10px 3px #ffffff !important;
}

.club-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 8px;
  border-radius: 10px;
  color: #ffffff;
  -webkit-transition: all 0.3s cubic-bezier(0.645,0.045,0.355,1);
  transition: all 0.3s cubic-bezier(0.645,0.045,0.355,1);
}

/**/

.club-card.active .shadow {
    background-color: transparent;
}

.club-card .shadow {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    pointer-events: none;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.25);
    -webkit-transition: background-color 0.3s cubic-bezier(0.645,0.045,0.355,1);
    transition: background-color 0.3s cubic-bezier(0.645,0.045,0.355,1);
}

/**/
/*
.club-card .aside {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 0 0 70px;
    -ms-flex: 0 0 70px;
    flex: 0 0 70px;
    padding-left: 8px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: rgba(255,255,255,0.3);
}

.club-card .aside__item:first-child {
    position: relative;
}

.club-card .aside__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 12px 0;
}
*/


.club-card .right {
  /*
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  */
  width: 100%;  

   

}

.club-card .right .card-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /*height: 130px;*/
    padding: 20px 15px 20px 15px;
    line-height: 1;
}

.card-info-1, .card-info-2, .card-info-3{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-right: 16px;
    line-height: 1;
    font-family: Segoe UI;
    font-weight: 600;
}

.card-info-1 .card-name {
    font-size: 23px;
    font-weight: 700;
    line-height: 1;
    cursor: default;
    color: #ffffff;
}

.card-info-1 .card-num {
    position: absolute;
    top: 0px;
    right: 4px;
}

.card-info-1 .card-credit {
    position: absolute;
    top: 20px;
    right: 4px;
}

.card-info-1 .card-tarif {
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    cursor: default;
    color: #ffffff;
}

.card-info-3{
    justify-content: center;
    font-size: 30px;
    font-weight: 700;
    margin-top:20px;
}
/*
.club-card .right .settings {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 60px;
    text-align: center;
    font-size: 11px;
    border-top: 3px solid rgba(255,255,255,0.3);
}
*/

.card-refill{
  position:absolute;
  right: 8px;
  bottom: 15px;
}

.button-card-refill {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    border-color: transparent;
}

.card-holdername{
  position: absolute;
  bottom: 23px;
  left: 27px;
}
/*
.club-card .right .settings__change-icon {
  height: 20px;
  fill: #ffffff;
}

.club-card .right .settings__item p {
    margin-top: 6px;
}
*/
.club-card p {
    margin: 0;
    line-height: 1;
}
/*
.club-card .right .settings__item {
    width: 33.33%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.club-card .right .settings__item.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.club-card .right .settings__item {
    width: 33.33%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
*/

@media (max-width: 575px) {  
  .card-info-3 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 14px;
  }
}


</style>
